import React, {Fragment, useState} from 'react'

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Tooltip,
    Button,
    IconButton,
    Paper,Table, TableHead, TableRow, TableCell, TableBody, TableContainer
} from "@mui/material"

import SummarizeIcon from '@mui/icons-material/Summarize';


import Draggable from 'react-draggable';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


function PaperComponent(props) {
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

const SummaryTable = ({summary}) => {
    return (<TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    <TableCell align="left">Rate</TableCell>
                    <TableCell align="left">LI Quantity</TableCell>
                    <TableCell align="left">Rate Amount</TableCell>
                    <TableCell align="left">Total Amount</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {summary && summary.map((line) => (
                    <TableRow
                        key={line.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">{line.name}</TableCell>
                        <TableCell align="left">{line.qty}</TableCell>
                        <TableCell align="left">{formatter.format(line.rate)}</TableCell>
                        <TableCell align="left">{formatter.format(line.amount)}</TableCell>
                    </TableRow>
                ))
                }
            </TableBody>
        </Table>
    </TableContainer>)
}


const ShowSummary = ({bill}) => {

    const initialState = {
        open: false,
    }

    const [dialog, setDialog] = useState(initialState)
    const { open } = dialog

    const handleClickOpen = () => { 
        setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        setDialog(initialState)
    }

    return (
        <Fragment>
            <IconButton color='inherit' onClick={handleClickOpen} >
                <Tooltip title='View Summary'>
                    <SummarizeIcon fontSize='small' style={{color: '#939393'}}/>
                </Tooltip>
            </IconButton>                   
    
            <Dialog open={open} id="billSummaryModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
                <DialogTitle id='form-dialog-title'>Bill Summary</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <SummaryTable summary={bill.Summary}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default ShowSummary