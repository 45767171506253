import { combineReducers } from 'redux'

import auth from '../features/auth/authSlice'
import billing from '../features/billing/billingSlice'
import org from '../features/organizations/orgSlice'
import dashboard from '../features/dashboard/dashboardSlice'
import queries from '../features/queries/querySlice'
import report from '../features/reports/reportSlice'
import aws from '../features/aws/awsSlice'
import rateChanges from '../features/rateChanges/rateChangeSlice'
import appHealth from '../features/appHealth/appHealthSlice'




export default  combineReducers({
    auth,
    billing,
    dashboard,
    org,
    queries,
    report,
    aws,
    rateChanges,
    appHealth
})