import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

// mui
import {Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography} from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


// components
import Spinner from '../../layouts/Spinner'
import { fCurrency } from '../../utils/formatNumber';

// context
import { OrgState } from './orgSlice'
import AddRateCode from './AddRateCode';
import EditRateCode from './EditRateCode';


const RateCodes = ({rates}) => {
    // const { Rates } = organization

    const { loading } = useSelector(OrgState)

    const Title = () => {
        return <Typography variant={'h6'} style={{marginTop:35}} >Rate Codes</Typography>
    }

    const getIcon = status => {
        switch (status) {
            case true: return <CheckCircle style={{color: 'green'}} />
            case false: return <HighlightOffIcon style={{color: 'red'}} />
        }
    }

    if (loading) return (
        <Fragment>
            <Title />
            <Spinner />
        </Fragment>
    )


    return (
        <Fragment>
            <Title />
            <AddRateCode  />
            {!rates && <p>No Rate Codes</p>}
            {rates && rates.length === 0 && <p>No Rate Codes</p>}
            {rates && rates.length > 0 && (
                <TableContainer component={Paper} sx={{ maxWidth: 800, marginBottom: 5}} >
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell ></TableCell>
                                <TableCell align="left">Code</TableCell>
                                <TableCell align="left">Rate</TableCell>
                                <TableCell align="left">Active</TableCell>
                                <TableCell align='left'>Default</TableCell>
                                <TableCell align="left">Selectable</TableCell>
                                <TableCell align="left">Source</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rates.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left" width={40}>{<EditRateCode rateCode={row}/>}</TableCell>
                                    <TableCell align="left">{row.display}</TableCell>
                                    <TableCell align="left">{fCurrency(row.rate)}</TableCell>
                                    <TableCell align="left">{getIcon(row.isActive)}</TableCell>
                                    <TableCell align="left">{getIcon(row.isDefault)}</TableCell>
                                    <TableCell align="left">{getIcon(row.selectable)}</TableCell>
                                    <TableCell align="left">{row.source}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Fragment>

    )
}

export default RateCodes