import React, { useEffect, Fragment } from 'react'
import moment from 'moment'

import { Collapse, TableContainer, Paper, Table, TableHead, TableRow,TableCell
  , Box, IconButton, Typography, TableBody
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import ShowSummary from './ShowSummary';
import { fCurrency } from '../../utils/formatNumber';
import DownloadAttachment from './DownloadAttachment';

function createData(billId, orgName, billDate, billAmount, lineItems, LineItems, Summary) {
  const lines = []
  LineItems.map(line => (
    lines.push({liID: line.id, liDescription: line.description, liAmount: line.amount, rate: line.Rate.display })
  ))

  return {
    billId,
    orgName,
    billDate,
    billAmount,
    lineItems,
    Lineitems: lines,
    Summary
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell size='small'>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell size='small' width={5}> <ShowSummary bill={row}/> </TableCell>
        <TableCell size='small' width={5}> <DownloadAttachment bill={row}/> </TableCell>
        <TableCell size='small' component="th" scope="row">
          {row.billId}
        </TableCell>
        {row.orgName && <TableCell size='small' align="left">{row.orgName}</TableCell>}
        <TableCell size='small' align="left">{moment(row.createdAt).format('YYYY-MM-DD')}</TableCell>
        <TableCell size='small' align="left">{row.billAmount}</TableCell>
        <TableCell size='small' align="left">{row.lineItems}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell size='small' style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Line Items
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell size='small' align="left">LI ID</TableCell>
                    <TableCell size='small' align="left">LI Description</TableCell>
                    <TableCell size='small' align="left">LI Amount</TableCell>
                    <TableCell size='small' align="left">Rate Code</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.Lineitems.map((LIRow) => (
                    <TableRow key={LIRow.liID}>
                      <TableCell size='small' align="left">{LIRow.liID}</TableCell>
                      <TableCell size='small' align="left" component="th" scope="row">
                        {LIRow.liDescription}
                      </TableCell>
                      <TableCell size='small' align="left">{fCurrency(LIRow.liAmount)}</TableCell>
                      <TableCell size='small' align="left">{LIRow.rate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

const BillAmount = ({lineItems}) => {
  let amount = 0
  lineItems.forEach(line => {
      amount += parseFloat(line.amount) 
  });

  //Handle 0 total amount without changing fCurrency
  amount = amount || '0.00'

  return fCurrency(amount)
}



const Bill = ({bills}) => {
  const rows = []
  let orgName
  bills.map((bill) => {
    orgName = bill.Organization?.name ?  bill.Organization.name : null
    rows.push(createData(bill.id, orgName, bill.createdAt, <BillAmount lineItems={bill.LineItems}/>, bill.LineItems.length, bill.LineItems, bill.Summary))
  })

  return (
    <TableContainer component={Paper} sx={{marginBottom:5, maxWidth:800}}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell size='small' />
            <TableCell size='small'/>
            <TableCell size='small'/>
            <TableCell size='small' align="left">Bill ID</TableCell>
            {orgName && <TableCell size='small' align="left">Organization</TableCell>}
            <TableCell size='small' align="left">Bill Date</TableCell>
            <TableCell size='small' align="left">Bill Amount</TableCell>
            <TableCell size='small' align="left">Line Items</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default Bill