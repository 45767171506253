import React, { Fragment } from 'react'

// import SearchAWS from './SearchAWS'
import BatchTable from './BatchTable'

const BatchFileUploads = props => {

        return (
            <Fragment>
                <h1>Files Uploaded</h1>
                <BatchTable/>
            </Fragment> 
        )

}

export default BatchFileUploads