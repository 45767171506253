import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Tooltip,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    Select
} from "@mui/material"

import Alert from '@mui/material/Alert';
import EditSharpIcon from '@mui/icons-material/EditSharp';

// context
import { updateUserAsync, clearError, OrgState } from "./orgSlice"

import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';


function PaperComponent(props) {
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  const Separator = () => {
    return <Grid item xs={12} >&nbsp;</Grid>
}

const EditUser = ({user}) => {

    const { error } = useSelector(OrgState)
    const dispatch = useDispatch()

    const initialState = {
        open: false,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        isActive: user.isActive,
        isSystemAccount: user.isSystemAccount,
        hgUser: user.hgUser
    }

    const [dialog, setDialog] = useState(initialState)
    const { open, firstName, lastName, email, isActive, isSystemAccount, hgUser} = dialog

    const handleClickOpen = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = (e) => {
        dispatch(clearError())
        dispatch(updateUserAsync({id:user.id,firstName, lastName, email, isActive, isSystemAccount, hgUser}))
        setDialog(initialState)
    }

    const onChange = e => setDialog({...dialog, [e.target.name]: e.target.value})

    return (
        <Fragment>
            <IconButton color='inherit' onClick={handleClickOpen} style={{marginLeft: "30px"}}>
                <Tooltip title='Edit User'>
                    <EditSharpIcon fontSize='small' style={{color: '#939393'}}/>
                </Tooltip>
            </IconButton>                   
    
            <Dialog open={open} id="editUserModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
                <DialogTitle id='form-dialog-title'>Update User Info</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { error && <Alert severity={'error'}>{error}</Alert> }
                    </DialogContentText>

                    <Separator />

                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                <TextField
                    type='text'
                    id='firstName'
                    name='firstName'
                    label='First Name'
                    value={firstName}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                    size={'small'}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='text'
                    id='lastName'
                    name='lastName'
                    label='Last Name'
                    value={lastName}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='text'
                    id='email'
                    name='email'
                    label='Email'
                    value={email}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined" sx={{minWidth: 125}} >
                <InputLabel size={'small'} htmlFor="outlined-age-native-simple">Status</InputLabel>
                <Select
                    native
                    size={'small'}
                    value={isActive}
                    onChange={onChange}
                    label="Status"
                    inputProps={{
                        name: 'isActive',
                        id: 'isActive',
                    }}
                    >
                    <option aria-label="None" value="" ></option>
                    <option aria-label='True' value='true' >Active</option>
                    <option aria-label="False" value="false" >In-Active </option>
                </Select>
            </FormControl>    
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined"  sx={{minWidth: 125}} >
                <InputLabel size={'small'} htmlFor="outlined-age-native-simple">System Account</InputLabel>
                <Select
                    native
                    size={'small'}
                    value={isSystemAccount}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="System Account"
                    inputProps={{
                        name: 'isSystemAccount',
                        id: 'isSystemAccount',
                    }}
                    >
                    <option aria-label="None" value="" />
                    <option aria-label='True' value='true'>Yes</option>
                    <option aria-label="False" value="false">No</option>
                </Select>
            </FormControl>    
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='text'
                    id='hgUser'
                    name='hgUser'
                    label='HG User'
                    value={hgUser}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
                </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default EditUser