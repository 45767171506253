import React, { Fragment } from 'react'
import ReportButtons from './ReportButtons';

const Reports = () => {

        return (
            <Fragment>
                <h1>Reports</h1>  
                <ReportButtons />
            </Fragment>
        )
}

export default Reports