import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

export const slice = createSlice({
    name: 'appHealth',
    initialState: {
        loading: false,
        data: null,
        downloadDetails: null,
        error: null
    },
    reducers: {
        loadData: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        loadDownloadDetails: (state, action) => {
            state.downloadDetails = action.payload
            state.loading = false
        },
        setLoading: (state, action) => {
            state.loading = true
        },
        setError: (state, action) => { state.error = action.payload},
        clearError: state => { state.error = null },
    }
})

export const { setLoading, loadData, loadDownloadDetails, setError, clearError } = slice.actions

export const getAppHealthDataAsync = () => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/AppHealth/`)
        dispatch(loadData(res.data))
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}

export const getDocumentDownloadDetailsAsync = () => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/AppHealth/$downloadDetails`)
        dispatch(loadDownloadDetails(res.data))
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}

export const updateDocServerAsync = ({id,status,field}) => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/v1/AppHealth/$docServer`,{id,status, field})
        dispatch(getAppHealthDataAsync())
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}


export const AppHealthState = state => state.appHealth

export default slice.reducer