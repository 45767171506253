import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import moment from 'moment' 

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Tooltip,
    Button,
    IconButton
} from "@mui/material"
import Alert from '@mui/material/Alert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';


// context
import {BillingState, undoBillRunAsync, clearError, } from './billingSlice'


function PaperComponent(props) {
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }


function ShowAlert(props) {
    return <Alert elevation={6} variant={'filled'} {...props} />
}

const Separator = () => {
    return <Grid item xs={12} >&nbsp;</Grid>
}

const UndoBillRun = ({billRun}) => {

    const { loading, error } = useSelector(BillingState)

    const dispatch = useDispatch()


    const initialState = {
        open: false,
    }

    const [dialog, setDialog] = useState(initialState)
    let { open, startDate, endDate } = dialog


    const handleClickOpen = () => { setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }

    const handleSubmit = (e) => {
        console.log('Submit Undo for Run: '+ billRun.id)

        dispatch(clearError())
        dispatch(undoBillRunAsync(billRun.id))
        setDialog(initialState)
    }
    



if (!loading) return (
    <Fragment>
        <IconButton color='inherit' onClick={handleClickOpen}>
        <Tooltip title='Undo Bill Run'>
            <DeleteForeverIcon style={{color: '#000032'}}/>
        </Tooltip>
        </IconButton>                   
 
    <Dialog open={open} id="undoModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'sm'} PaperComponent={PaperComponent}>
    <DialogTitle id='form-dialog-title'>Undo Bill Run</DialogTitle>
    <DialogContent>

        <DialogContentText>
            {
                error && <ShowAlert severity={'error'}>{error}</ShowAlert>
            }
        </DialogContentText>

        
        <DialogContentText>You are about to undo bill run {billRun.id}</DialogContentText>
        <DialogContentText>Do you wish to continue?</DialogContentText>
        <Separator />
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose} color={'primary'}>
            Cancel
        </Button>
        <Button onClick={handleSubmit} color={'primary'}>
            Submit
        </Button>
    </DialogActions>
</Dialog>
</Fragment>
)
else 
        return (<div></div>)
}
export default UndoBillRun