import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import moment from 'moment' 
import { useStates } from 'react-us-states';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Tooltip,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    Select
} from "@mui/material"
import Alert from '@mui/material/Alert';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';


// context
import {OrgState, addNewOrgAsync, clearError, } from './orgSlice'


function PaperComponent(props) {
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }


function ShowAlert(props) {
    return <Alert elevation={6} variant={'filled'} {...props} />
}

const Separator = () => {
    return <Grid item xs={12} >&nbsp;</Grid>
}

const AddOrganization = (props) => {

    const { loading, error } = useSelector(OrgState)

    const dispatch = useDispatch()

    const initialState = {
        open: false,
        name: '',
        street: '',
        region: '',
        postalCode: '',
        referral: '',
        city: '',
        rate: '',
        authId: ''
    }

    const [dialog, setDialog] = useState(initialState)
    let { open, name, street, region, postalCode, city, rate, authId } = dialog


    const handleClickOpen = () => { setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }

    const handleSubmit = (e) => {
        const organization = {name, street, city, state: region,postalCode, oAuthClientId:authId }
        console.log(organization)
        // dispatch(clearError())
        dispatch(addNewOrgAsync(organization,rate))
        setDialog(initialState)
    }
    
    const onChange = e => {
        setDialog({...dialog, [e.target.name]: e.target.value})
    }

    const stateOptions = useStates().map((reg, index) => {
        return {
            label: reg.name,
            value: reg.abbreviation,
            key: index
         }
    })



if (!loading) return (
    <Fragment>
        <IconButton color='inherit' onClick={handleClickOpen}>
        <Tooltip title='Add Organization'>
            <AddCircleOutlineIcon fontSize='large' style={{color: '#000032'}}/>
        </Tooltip>
        </IconButton>                   
 
    <Dialog open={open} id="addModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'sm'} PaperComponent={PaperComponent}>
    <DialogTitle id='form-dialog-title'>Add Organization</DialogTitle>
    <DialogContent>

        <DialogContentText>
            {
                error && <ShowAlert severity={'error'}>{error}</ShowAlert>
            }
        </DialogContentText>

        
        {/* <DialogContentText>Please select a start and end date.</DialogContentText> */}
        {/* <DialogContentText>End date is bill to, will not include queries made on that date.</DialogContentText> */}
        <Separator />
        <Grid container spacing={2}>
        <Grid item xs={12}>
                <TextField
                    type='text'
                    id='name'
                    name='name'
                    label='Name'
                    value={name}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                    required
                    size={'small'}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='text'
                    id='street'
                    name='street'
                    label='Street Address'
                    value={street}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='text'
                    id='city'
                    name='city'
                    label='City'
                    value={city}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined" >
                <InputLabel size={'small'} htmlFor="outlined-age-native-simple">State</InputLabel>
                <Select
                    native
                    size={'small'}
                    value={region}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="State"
                    inputProps={{
                        name: 'region',
                        id: 'region',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {stateOptions.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>
                    
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='text'
                    id='postalCode'
                    name='postalCode'
                    label='Postal Code'
                    value={postalCode}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='number'
                    id='rate'
                    name='rate'
                    label='Default Rate'
                    value={rate}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='text'
                    id='authId'
                    name='authId'
                    label='Auth Id'
                    value={authId}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
        </Grid>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose} color={'primary'}>
            Cancel
        </Button>
        <Button onClick={handleSubmit} color={'primary'}>
            Submit
        </Button>
    </DialogActions>
</Dialog>
</Fragment>
)
else 
        return (<div></div>)
}
export default AddOrganization