import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fNumber } from '../../utils/formatNumber';

// mui
import { DataGrid, GridToolbar, getGridStringOperators } from '@mui/x-data-grid';

import { ReportState, getFacilitiesAsync} from './reportSlice'
import Spinner from '../../layouts/Spinner'




const FacilitiesReport = props => {
    const dispatch = useDispatch()

    const {facilities } = useSelector(ReportState)

    useEffect(() => {
        dispatch(getFacilitiesAsync())
    }, [])

    //Defines Data Grid columns
    const columnsFormat = [
        {field: 'source', headerName: 'Facility', width: 600,
            renderCell: (params) => {
                return params.row.source
            }},
        {field: 'author', headerName: 'Author', width: 250,
            renderCell: (params) => {
                return params.row.author
            }},
        {field: 'custodian', headerName: 'Custodian', width: 250,
            renderCell: (params) => {
                return params.row.custodian
            }},
        {field: 'encounterFacility', headerName: 'Encounter Facility', width: 250,
            renderCell: (params) => {
                return params.row.encounterFacility
            }},
        {field: 'returnCount', headerName: '# Documents', width: 150,
            renderCell: (params) => {
                return fNumber(params.row.returnCount)
            }}
        
    ]

    const columns = React.useMemo( () =>
        columnsFormat.map((col) => {
            return {
            ...col,
            filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
            ),
            };
        }),
        [columnsFormat],
    );

 

       if(facilities){
                return (
                    <Fragment>
                        <h1>Facilities Report</h1>

                        <div>
                            <div style={{ height: 650, width: '100%' }}>
                                <DataGrid
                                    getRowId={(row) => row?.source + row.returnCount}
                                    components={{
                                        Toolbar: GridToolbar
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                        csvOptions: { disableToolbarButton: false },
                                        printOptions: { disableToolbarButton: true },
                                        },
                                    }}
                                    style={{
                                        backgroundColor: 'white'
                                    }}
                                    initialState={{
                                        sorting: {
                                          sortModel: [{ field: 'source', sort: 'asc' }],
                                        },
                                      }}
                                    rows={facilities}
                                    columns={columns}
                                    rowsPerPageOptions={[25,50,100]}   
                                />
                            </div>
                        </div>
                        
                    </Fragment>
                )
            }
        else return <Spinner />

}

export default FacilitiesReport