import React from 'react';

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardSharpIcon from '@mui/icons-material/DashboardSharp';
import Tooltip from '@mui/material/Tooltip';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AssessmentIcon from '@mui/icons-material/Assessment';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import { Link } from "react-router-dom";



export const mainNavigationItems = (open) => {
    
    return (<div>
        
        <ListItem button component={Link} to='/'>
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Home'} placement='right'>
                <DashboardSharpIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Home" />
        </ListItem>
        
        
        <ListItem button component={Link} to='/organizations' >
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Organizations'} placement='right'>
                <CorporateFareIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Organizations"  />
        </ListItem>

         <ListItem button component={Link} to='/billing' >
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Billing'} placement='right'>
                <AttachMoneyIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Billing"  />
        </ListItem>

        <ListItem button component={Link} to='/queries' >
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Query Data'} placement='right'>
                <QueryStatsIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Query Data"  />
        </ListItem>

        <ListItem button component={Link} to='/reports' >
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Reports'} placement='right'>
                <AssessmentIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Reports"  />
        </ListItem>

        <ListItem button component={Link} to='/batches' >
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Uploaded Files'} placement='right'>
                <UploadFileIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Uploaded Files"  />
        </ListItem>

        <ListItem button component={Link} to='/rateChanges' >
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Rate Changes'} placement='right'>
                <PriceChangeIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Rate Changes"  />
        </ListItem>

        <ListItem button component={Link} to='/appHealth' >
            <ListItemIcon>
            <Tooltip title={open ? '' : 'App Health'} placement='right'>
                <AppsOutageIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="App Health"  />
        </ListItem>
 
    </div>)
}

export const secondaryListItems = (
    <div>

    </div>
)