import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

// mui
import {Grid,Typography} from '@mui/material'

// components
import Spinner from '../../layouts/Spinner'
// import OrganizationBill from './OrganizationBill12';
import Bill from '../billing/Bill';
// context
import { OrgState } from './orgSlice'

const Separator = () => {
    return <Grid item xs={12} >&nbsp;</Grid>
}

const OrganizationBilling = ({Bills}) => {

    const { loading } = useSelector(OrgState)

    const Title = () => {
        return <Typography variant={'h6'} style={{marginTop: '30px'}} >Bills</Typography>
    }

    if (loading) return (
        <Fragment>
            <Title />
            <Spinner />
        </Fragment>
    )


    return (
        <Fragment>
            <Title />
            <Separator />
            {Bills.length === 0 && <p>No Bills</p>}
            {Bills.length > 0 && <Bill bills={Bills} />  }   
        </Fragment>
    )
}

export default OrganizationBilling