import React from 'react'

import { Grid } from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import GroupsIcon from '@mui/icons-material/Groups';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

import AppWidgetSummary from '../dashboard/AppWidgetSummary'


const OrganizationWidgets = ({patients}) => {

const tempQueries = []
const queryArray = patients.map(p => [...tempQueries,...p.PatientQueries])
const queryLength = queryArray.map(p => p.length)
const queryCount = queryLength.reduce((partialSum, a) => partialSum + a, 0);

const tempBilledQueries = []
const queryBilledArray = patients.map(p => [...tempBilledQueries,...p.PatientQueries])
const queryBilledLength = queryBilledArray.map((p) => { 
       let temp = 0
       p.map((p2) => {
            if(p2.Rate?.rate != 0) temp += 1 
                })
        return temp})
const queryBilledCount = queryBilledLength.reduce((partialSum, a) => partialSum + a, 0);

const tempUnBilledQueries = []
const queryUnBilledArray = patients.map(p => [...tempUnBilledQueries,...p.PatientQueries])
const queryUnBilledLength = queryUnBilledArray.map((p) => { 
       let temp = 0
       p.map((p2) => {
            if(p2.Rate?.rate == 0) temp += 1 
                })
        return temp})
const queryUnBilledCount = queryUnBilledLength.reduce((partialSum, a) => partialSum + a, 0);

return(
<Grid container spacing={3} sx={{}}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Patients" total={patients.length} height={{min:250,max:250}} icon={<GroupsIcon />} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Queries" total={queryCount} height={{min:250,max:250}} color="info" icon={<AnalyticsIcon />} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Billable Queries" total={queryBilledCount} height={{min:250,max:250}} color="warning" icon={<PaidIcon />} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Non-Billable Queries" total={queryUnBilledCount} height={{min:250,max:250}} color="error" icon={<MoneyOffIcon />} />
          </Grid>
    </Grid>
)
}

export default OrganizationWidgets