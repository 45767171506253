import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import moment from 'moment' 

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Tooltip,
    Button,
    IconButton
} from "@mui/material"
import Alert from '@mui/material/Alert';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';


// context
import {BillingState, createBillRunAsync, clearError, } from './billingSlice'


function PaperComponent(props) {
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }


function ShowAlert(props) {
    return <Alert elevation={6} variant={'filled'} {...props} />
}

const Separator = () => {
    return <Grid item xs={12} >&nbsp;</Grid>
}

const AddBillRun = (props) => {

    const { loading, error } = useSelector(BillingState)

    const dispatch = useDispatch()

    const currentMonth = moment().startOf('month').format('YYYY-MM-DD')
    const priorMonth = moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD')

    const initialState = {
        open: false,
        startDate: priorMonth,
        endDate: currentMonth,
    }

    const [dialog, setDialog] = useState(initialState)
    let { open, startDate, endDate } = dialog


    const handleClickOpen = () => { setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }

    const handleSubmit = (e) => {

        console.log(`Start: ${startDate}, End: ${endDate}`)
        dispatch(clearError())
        dispatch(createBillRunAsync({startDate, endDate}))
        setDialog(initialState)
    }
    
    const onChange = (name,e) => {
        if(name == 'start') setDialog({...dialog, startDate: e.target.value})
        if(name == 'end') setDialog({...dialog, endDate: e.target.value})
    }



if (!loading) return (
    <Fragment>
        <IconButton color='inherit' onClick={handleClickOpen}>
        <Tooltip title='Create Bill Run'>
            <AddCircleOutlineIcon fontSize='large' style={{color: '#000032'}}/>
        </Tooltip>
        </IconButton>                   
 
    <Dialog open={open} id="addModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'sm'} PaperComponent={PaperComponent}>
    <DialogTitle id='form-dialog-title'>Create Bill Run</DialogTitle>
    <DialogContent>

        <DialogContentText>
            {
                error && <ShowAlert severity={'error'}>{error}</ShowAlert>
            }
        </DialogContentText>

        
        <DialogContentText>Please select a start and end date.</DialogContentText>
        <DialogContentText>End date is bill to, will not include queries made on that date.</DialogContentText>
        <Separator />
        <Grid container spacing={2}>
            <Grid item xs={12}>
            <TextField
                id="startDate"
                label="Start Date"
                type="date"
                defaultValue={startDate}
                onChange={(newValue) => {
                    onChange('start',newValue);
                  }}
                sx={{ width: 220, marginTop: 1 }}
                InputLabelProps={{
                shrink: true,
                }}
            />
            
            <TextField
                id="endDate"
                label="End Date"
                type="date"
                defaultValue={endDate}
                onChange={(newValue) => {
                    onChange('end',newValue);
                  }}
                sx={{ width: 220, marginTop: 1, marginLeft: 2 }}
                InputLabelProps={{
                shrink: true,
                }}
            />
            </Grid>
        </Grid>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose} color={'primary'}>
            Cancel
        </Button>
        <Button onClick={handleSubmit} color={'primary'}>
            Submit
        </Button>
    </DialogActions>
</Dialog>
</Fragment>
)
else 
        return (<div></div>)
}
export default AddBillRun