import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Template from './layouts/Template'
import Home from './layouts/Home'
import Unauthorized from './layouts/Unauthorized'
import Organizations from './features/organizations/Organizations'
import Billing from './features/billing/Billing'
import OrganizationDetail from './features/organizations/OrganizationDetail'
import BillRunDetail from './features/billing/BillRunDetail'
import Logout from './layouts/Logout'
import Queries from './features/queries/Queries'
import Reports from './features/reports/Reports'
import FacilitiesReport from './features/reports/FacilitiesReport'
import NetworksReport from './features/reports/NetworksReport'
import BatchReport from './features/reports/BatchReport'
import RxHome from './features/rx/RxHome'
// import AWSRecords from './features/aws/AWSRecords'
import BatchFileUploads from './features/aws/BatchFileUploads'
import RateChanges from './features/rateChanges/RateChanges'
import AppHealth from './features/appHealth/AppHealth'

// mui
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";

import { withAuthenticationRequired } from '@auth0/auth0-react'

import './App.css'

const theme = createTheme({
  palette: {
      background: {
          // default: "#000032"
      },
      primary: {
          main: '#000032',
      },
      secondary: {
          main: '#94c4f5',
      }
  }
})

function App() {
    return (
      <ThemeProvider theme={theme}>
          <Router>
              <Routes>
                  <Route path='/' element={<Template />}>
                      <Route index element={<Home />} />
                      <Route exact path={'/appHealth'} element={<AppHealth />} />
                      <Route exact path={'/batches'} element={<BatchFileUploads />} />
                      <Route exact path={'/organizations'} element={<Organizations />} />
                      <Route exact path={'/organizations/:id'} element={<OrganizationDetail />} />
                      <Route exact path={'/billing'} element={<Billing />} />
                      <Route exact path={'/billing/:id'} element={<BillRunDetail />} />
                      <Route exact path={'/logout'} element={<Logout />} />
                      <Route exact path={'/queries'} element={<Queries />} />
                      <Route exact path={'/rateChanges'} element={<RateChanges />} />
                      <Route exact path={'/reports'} element={<Reports />} />
                      <Route exact path={'/reports/batches'} element={<BatchReport />} />
                      <Route exact path={'/reports/facilities'} element={<FacilitiesReport />} />
                      <Route exact path={'/reports/networks'} element={<NetworksReport />} />
                      <Route exact path={'/rx'} element={<RxHome />} />
                  </Route>
                  <Route path='/Unauthorized' element={<Unauthorized />}></Route>
              </Routes>
          </Router>
      </ThemeProvider>
  )
}

export default withAuthenticationRequired(App)
