import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

export const slice = createSlice({
    name: 'queries',
    initialState: {
        loading: false,
        queryData: [],
        // rates: [],
        error: null
    },
    reducers: {
        loadQueryData: (state, action) => {
            state.queryData = action.payload
            state.loading = false
        },
        setLoading: (state, action) => {
            state.loading = false
        },
        setError: (state, action) => { state.error = action.payload},
        clearError: state => { state.error = null },
    }
})

export const { setLoading, loadQueryData, setError, clearError } = slice.actions

export const getQueryDataAsync = () => async dispatch => {
    // dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Queries`)
        dispatch(loadQueryData(res.data))
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}


export const QueriesState = state => state.queries

export default slice.reducer