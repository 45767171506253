import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// state
import { BillingState, getBillRunsAsync} from './billingSlice'
import { fCurrency } from '../../utils/formatNumber';

import Spinner from '../../layouts/Spinner'

// MUI
import { DataGrid, GridToolbar, getGridStringOperators } from '@mui/x-data-grid';
import { Box, Link, Tooltip} from "@mui/material"
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import Alert from '@mui/material/Alert';

import AddBillRun from './AddBillRun'
import UndoBillRun from './UndoBillRun'

function ShowAlert(props) {
    return <Alert elevation={6} variant={'filled'} {...props} />
}

function getBillRun(props) {
    return <Alert elevation={6} variant={'filled'} {...props} />
}

const BillRunsDG = props => {
    const dispatch = useDispatch()

    const {loading, billRuns, error } = useSelector(BillingState)

    useEffect(() => {
        dispatch(getBillRunsAsync())
    }, [])
 

    //Defines Data Grid columns
    const columnsFormat = [
        {field: 'viewDetails', headerName: '', width: 50, sortable: false, align: 'center',filterable: false, sortable: false,
            renderCell: (params) => {
                return <Link href={"/billing/"+ params.row.id}><Tooltip title='View Run Details'><RequestQuoteIcon /></Tooltip></Link>
            }},
        {field: 'undoBillRun', headerName: '', width: 50, sortable: false, align: 'center',filterable: false, sortable: false,
            renderCell: (params) => {
                return <UndoBillRun billRun={params}/>
            }},
        {field: 'id', headerName: 'Bill Run ID', width: 150,
            renderCell: (params) => {
                return params.row.id
            }},
        {field: 'createdAt', headerName: 'Bill Run Date', width: 150,
            renderCell: (params) => {
                return moment(params.row.createdAt).isValid() ? moment(params.row.createdAt).format('YYYY-MM-DD') : ''
            }},
        {field: 'startDate', headerName: 'Bill From Date', width: 150,
            renderCell: (params) => {
                return moment(params.row.startDate).isValid() ? moment(params.row.startDate).format('YYYY-MM-DD') : ''
            }},
        {field: 'endDate', headerName: 'Bill To Date', width: 150,
            renderCell: (params) => {
                return moment(params.row.endDate).isValid() ? moment(params.row.endDate).format('YYYY-MM-DD') : ''
            }},
        {field: 'billRunTotal', headerName: 'Run Total', width: 100,
            renderCell: (params) => {
                return fCurrency(params.row.billRunTotal)
            }},
        
    ]

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

    if(billRuns.length > 0 ) {
        return (<Fragment>
            {
                error && <ShowAlert severity={'error'}>{error}</ShowAlert>
            }
            <div>
                <Box display='flex' justifyContent='flex-end'>
                <AddBillRun/>
                </Box>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: GridToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                              csvOptions: { disableToolbarButton: true },
                              printOptions: { disableToolbarButton: true },
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={billRuns}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}   
                    />
                </div>
            </div>
            </Fragment>
        )
        }
        else
            // return <Spinner />
            return <div> No Bill Runs
                    <Box display='flex' justifyContent='flex-end'>
                        <AddBillRun/>
                    </Box>
                 </div>
}


export default BillRunsDG