import React, { Fragment, useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'


import Spinner from '../../layouts/Spinner'

// MUI
import { Box, Tooltip,
         Card, Grid, Typography,
         Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer,
        } from "@mui/material"

import { CheckCircle } from '@mui/icons-material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const Label = ({ text }) => {
    return <Typography variant={'body1'} style={{color: "#7f8c8d" }}>
        {text}
    </Typography>
}
const getIcon = status => {
    switch (status) {
        case 'pass': return <CheckCircle style={{color: 'green'}} />
        case 'fail': return <HighlightOffIcon style={{color: 'red'}} />
    }
}


const SystemAlarms = ({data}) => { 

    if(!data) return <Spinner />
    return <Fragment>
            <Card sx={{ width: 3/4, marginLeft: 1 }}>
                <Box sx={{ marginLeft: 2, marginBottom: 2}}>
                    <h3>System Health</h3>
                    {/* <TableContainer component={Paper} sx={{marginBottom: 5, paddingRight:2}} > */}
                    <Table sx={{ minWidth: 650, marginRight:2 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">System</TableCell>
                                <TableCell align="left">Test</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align='left'>Details</TableCell>
                                <TableCell align="left">Last Check</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <TableRow
                                    key={row.system + row.test}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{row.system}</TableCell>
                                    <TableCell align="left">{row.test}</TableCell>
                                    <TableCell align="left">{getIcon(row.status)}</TableCell>
                                    <TableCell align="left">{row.details}</TableCell>
                                    <TableCell align="left">{row.createdAt ? moment(row.createdAt).format('YYYY-MM-DD HH:mm') : ''}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                {/* </TableContainer> */}
                </Box>
            </Card>
        </Fragment>

}

export default SystemAlarms