import React, { Fragment } from 'react'

import BillRunsDG from './BillRunsDG'

const Billing = props => {

        return (
            <Fragment>
                <h1>Billing</h1>
                <BillRunsDG />
            </Fragment>
        )

}

export default Billing