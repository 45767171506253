import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

export const slice = createSlice({
    name: 'org',
    initialState: {
        orgs: [],
        organization: null,
        orgsBasic: [],
        loading: true,
        error: null,
        rates: [],
        bills: [],
        people: []
    },
    reducers: {
        getOrgs: (state, action) => {
            state.orgs = action.payload
            state.loading = false
        },
        getRates: (state, action) => {
            state.rates = action.payload
            state.loading = false
        },
        getBills: (state, action) => {
            state.bills = action.payload
            state.loading = false
        },
        getPeople: (state, action) => {
            state.people = action.payload
            state.loading = false
        },
        getOrgsBasic: (state, action) => {
            state.orgsBasic = action.payload
            state.loading = false
        },
        getOrganization: (state, action) => {
            state.organization = action.payload
            state.loading = false
        },
        setLoading: (state, action) => {
            state.loading = false
        },
        clearError: state => { state.error = null },
        setError: (state, action) => { state.error = action.payload}
    }
})

export const { setLoading, getOrgs, getOrgsBasic, getOrganization, clearError, setError, getRates, getBills, getPeople } = slice.actions

export const getOrgsAsync = () => async dispatch => {
    // dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Organizations`)
        dispatch(getOrgs(res.data))
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}

export const addNewOrgAsync = (organization, rate) => async dispatch => {
    // dispatch(setLoading())
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/Organizations`, {organization, rate})
        dispatch(getOrgsAsync())
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}

export const updateOrganizationAsync = organization => async dispatch => {
    dispatch(setLoading())

    try {
        if (!organization) return
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/v1/Organizations/${organization.id}`,{organization})
        window.location.reload()
        // dispatch(loadPerson(res.data))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const addNewUserAsync = user => async dispatch => {
    dispatch(setLoading())

    try {
        if (!user) return
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/Organizations/$User`,{user})
        window.location.reload()
        // dispatch(loadPerson(res.data))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const updateUserAsync = user => async dispatch => {
    dispatch(setLoading())

    try {
        if (!user) return
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/v1/Organizations/$User/${user.id}`,{user})
        window.location.reload()
        // dispatch(loadPerson(res.data))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const addNewRateAsync = rate => async dispatch => {
    dispatch(setLoading())

    try {
        if (!rate) return
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/Organizations/$Rate`,{rate})
        // window.location.reload()
        dispatch(getRatesAsync(rate.tenantId))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const getRatesAsync = id => async dispatch => {
    dispatch(setLoading())

    try {
        if (!id) return
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Organizations/$Rate/${id}`)
        // window.location.reload()
        dispatch(getRates(res.data))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const updateRateAsync = rate => async dispatch => {
    dispatch(setLoading())

    try {
        if (!rate) return
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/v1/Organizations/$Rate/${rate.id}`,{rate})
        // window.location.reload()
        dispatch(getRatesAsync(rate.tenantId))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}


export const getOrgsBasicAsync = () => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Organizations/$basic`, {})
        dispatch(getOrgsBasic(res.data ))
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}

export const getOrganizationAsync = (id) => async dispatch => {
    // dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Organizations/${id}`)
        dispatch(getOrganization(res.data))
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}

export const getBillsAsync = id => async dispatch => {
    dispatch(setLoading())

    try {
        if (!id) return
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Organizations/$Bill/${id}`)
        // window.location.reload()
        dispatch(getBills(res.data))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const getPeopleAsync = id => async dispatch => {
    dispatch(setLoading())

    try {
        if (!id) return
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Organizations/$People/${id}`)
        // window.location.reload()
        dispatch(getPeople(res.data))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const OrgState = state => state.org

export default slice.reducer