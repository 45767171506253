
import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles';
import { Typography,ButtonBase, Grid } from '@mui/material'
// import FolderSharedSharpIcon from '@mui/icons-material/FolderSharedSharp';
import { Link } from "react-router-dom";

// const images = [
//   {
//     url: '../../assets/icon8-contact-100.png',
//     title: 'Person Management',
//     width: '40%',
//   },
//   {
//     url: '/static/images/buttons/burgers.jpg',
//     title: 'Upload File',
//     width: '30%',
//   },
// ];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: .85,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

// const ImageSrc = styled('span')({
//   position: 'absolute',
//   left: 0,
//   right: 0,
//   top: 0,
//   bottom: 0,
//   backgroundSize: 'cover',
//   backgroundPosition: 'center 40%',
// });

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: '#000032',
//   opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: 'white',
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));


export default function ReportButtons() {
  return ( <Fragment>
          <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={3}>
                  <ImageButton
                      focusRipple
                      style={{
                          width: '100%',
                      }}
                  >
                      <ImageBackdrop className="MuiImageBackdrop-root" />
                      <Link to="/reports/batches" style={{color: 'white'}}>
                          <Image>
                              <Typography
                                  component="span"
                                  variant="subtitle1"
                                  color="white"
                                  sx={{
                                      position: 'relative',
                                      p: 4,
                                      pt: 2,
                                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                  }}
                              >
                                  Batches Report
                                  <ImageMarked className="MuiImageMarked-root" />
                              </Typography>
                          </Image>
                      </Link>
                  </ImageButton>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                  <ImageButton
                      focusRipple
                      style={{
                          width: '100%',
                      }}
                  >
                      <ImageBackdrop className="MuiImageBackdrop-root" />
                      <Link to="/reports/facilities" style={{color: 'white'}}>
                          <Image>
                              <Typography
                                  component="span"
                                  variant="subtitle1"
                                  color="white"
                                  sx={{
                                      position: 'relative',
                                      p: 4,
                                      pt: 2,
                                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                  }}
                              >
                                  Facilities Report
                                  <ImageMarked className="MuiImageMarked-root" />
                              </Typography>
                          </Image>
                      </Link>
                  </ImageButton>
              </Grid>
               <Grid item xs={12} sm={6} lg={3}>
                  <ImageButton
                      focusRipple
                      style={{
                          width: '100%',
                      }}
                  >
                      <ImageBackdrop className="MuiImageBackdrop-root" />
                      <Link to="/reports/networks" style={{color: 'white'}}>
                          <Image>
                              <Typography
                                  component="span"
                                  variant="subtitle1"
                                  color="white"
                                  sx={{
                                      position: 'relative',
                                      p: 4,
                                      pt: 2,
                                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                  }}
                              >
                                  Networks Report
                                  <ImageMarked className="MuiImageMarked-root" />
                              </Typography>
                          </Image>
                      </Link>
                  </ImageButton>
              </Grid>
              {/*<Grid item xs={12} sm={6} lg={3}>
                  <ImageButton
                      focusRipple
                      style={{
                          width: '100%',
                      }}
                  >
                      <ImageBackdrop className="MuiImageBackdrop-root" />
                      <Link to="/commonwellLink" style={{color: 'white'}}>
                          <Image>
                              <Typography
                                  component="span"
                                  variant="subtitle1"
                                  color="white"
                                  sx={{
                                      position: 'relative',
                                      p: 4,
                                      pt: 2,
                                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                  }}
                              >
                                  Patient Linking
                                  <ImageMarked className="MuiImageMarked-root" />
                              </Typography>
                          </Image>
                      </Link>
                  </ImageButton>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                  <ImageButton
                      focusRipple
                      style={{
                          width: '100%',
                      }}
                  >
                      <ImageBackdrop className="MuiImageBackdrop-root" />
                      <Link to="/requests" style={{color: 'white'}}>
                          <Image>
                              <Typography
                                  component="span"
                                  variant="subtitle1"
                                  color="white"
                                  sx={{
                                      position: 'relative',
                                      p: 4,
                                      pt: 2,
                                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                  }}
                              >
                                  Requests
                                  <ImageMarked className="MuiImageMarked-root" />
                              </Typography>
                          </Image>
                      </Link>
                  </ImageButton>
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                  <ImageButton
                      focusRipple
                      style={{
                          width: '100%',
                      }}
                  >
                      <ImageBackdrop className="MuiImageBackdrop-root" />
                      <Link to="/archive" style={{color: 'white'}}>
                          <Image>
                              <Typography
                                  component="span"
                                  variant="subtitle1"
                                  color="white"
                                  sx={{
                                      position: 'relative',
                                      p: 4,
                                      pt: 2,
                                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                  }}
                              >
                                  Patient Archive
                                  <ImageMarked className="MuiImageMarked-root" />
                              </Typography>
                          </Image>
                      </Link>
                  </ImageButton>
              </Grid>*/}
          </Grid> 


      {/* <ImageButton
                focusRipple
                style={{
                  width: '30%',
                }}
              >
              <ImageBackdrop  className="MuiImageBackdrop-root" />
              <Link to="/referrals" style={{color: 'white'}}>
                <Image>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="white"
                    sx={{
                      position: 'relative',
                      p: 4,
                      pt: 2,
                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                    }}
                  >
                    Referrals
                    <ImageMarked className="MuiImageMarked-root" />
                  </Typography>
                </Image>
                </Link>
              </ImageButton> */}
    </Fragment>
  );
}