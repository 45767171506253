// @mui
import { styled } from '@mui/material/styles';
import UploadRxReturn from './UploadRxReturn';
//


 const  RxHome = () => {
 

  return (
    <div>
        <h1>Rx Home</h1>
        <UploadRxReturn />
    </div>
  );
}

export default RxHome