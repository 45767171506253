import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateRangeIcon from '@mui/icons-material/DateRange';
import GroupsIcon from '@mui/icons-material/Groups';
import AnalyticsIcon from '@mui/icons-material/Analytics';

// sections
import PieChart from './PieChart';
import BarGraph from './BarGraph'
import AppWidgetSummary from './AppWidgetSummary'

import {DashboardState, getDashboardAsync} from './dashboardSlice'
import moment from 'moment' 


// ----------------------------------------------------------------------

const DashboardDetails = () => {
  const theme = useTheme();
  const dispatch = useDispatch()

    const {loading, monthlyQueries, monthlyPatients, weeklyQueries, weeklyPatients, error, yearlyQueries } = useSelector(DashboardState)

    useEffect(() => {
        dispatch(getDashboardAsync())
    }, [])

    const monthNum = moment().month() +1
    const dates = []
    let i = 0

    while(i < monthNum){ 
        dates.push(moment().month(i).format('MMMM')) 
        i++
    }

    //totals amounts for each rate and formats for PieChart
    const billingResults = Object.values(
                  monthlyQueries.reduce((agg, query) => {
                    if (agg[query.Rate.display] === undefined) agg[query.Rate.display] = { label: query.Rate.display, value: 0 }
                    agg[query.Rate.display].value += +query.Rate.rate
                    return agg
                    }, {})
                )
  
    const billingTotal = billingResults.reduce((partialSum, a) => partialSum + a.value, 0);
    const subheader = `Total Billing: $${billingTotal}`

    const finalResults = billingResults.filter(i => i.value > 0)

   //Combines like rate counts across months         
    const yearlyObject = Object.values(
        yearlyQueries.reduce((agg, query) => {
          if (agg[query.Rate.display] === undefined) agg[query.Rate.display] = { name: query.Rate.display, data: [] }
          agg[query.Rate.display].data.push({month: query.month, data: query.Rate.count})
          return agg
          }, {})
      )

    const yearlyResults = yearlyObject.map((r,index) =>{
      const temp = r.data.reduce((agg, query, i) => {
                  if (agg[query.month] === undefined) agg[query.month] = { month:query.month, data: 0 }
                  agg[query.month].data += +query.data 
                  return agg
                  }, {})
          return {name:r.name, data: temp}
    })
    
    const chartData = yearlyResults.map((r,index) => {

      const data = []
      let color = ''
      let x = 1
      while(x <= monthNum){
        if(r.data[x]){
          data.push(r.data[x].data)
        }
        else data.push(0)
        x++
      }

      if(r.name == 'Insufficient Data') color = '#f7564a'
      if(r.name == 'Requery') color = '#6926bf'
      if(r.name == 'Standard') color = '#2d8524'
      if(r.name == 'Discount SEB') color = '#3870e8'
      if(r.name == 'Pilot (No Charge)') color = '#e89f38'

        return {name:   r.name,    
                type: 'column',
                fill: 'solid',
                color: color,
                data: data }
            })

  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Weekly Patients" total={weeklyPatients.length} height={{min:250,max:250}} icon={<GroupsIcon />} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Weekly Queries" total={weeklyQueries.length} height={{min:250,max:250}} color="info" icon={<AnalyticsIcon />} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Monthly Patients" total={monthlyPatients.length} height={{min:250,max:250}} color="warning" icon={<CalendarMonthIcon />} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Monthly Queries" total={monthlyQueries.length} height={{min:250,max:250}} color="error" icon={<DateRangeIcon />} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <BarGraph
              title="Query Details"
              subheader="By Rate Code"
              chartLabels={dates}
              chartData={chartData}
            /> 
          </Grid>

          <Grid item xs={12} md={6} lg={4} sx={{marginBottom: 5}}>
            <PieChart
              title="Monthly Billing Breakdown"
              subheader={subheader}
              chartData={finalResults}
              chartColors={[ '#2d8524', '#f7564a', '#6926bf','#3870e8','#e89f38']}
            />
          </Grid>

        </Grid>
      </Container>
    </>
  );
}

export default DashboardDetails