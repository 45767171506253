import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Grid } from '@mui/material';
import PercentIcon from '@mui/icons-material/Percent';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CalculateIcon from '@mui/icons-material/Calculate';

import {QueriesState, getQueryDataAsync} from './querySlice'
import AppWidgetSummary from '../dashboard/AppWidgetSummary'
import QueriesDG from './QueriesDG';

const Separator = () => {
    return <Grid item xs={12} >&nbsp;</Grid>
}

const Queries = props => {
    const dispatch = useDispatch()

    const {queryData } = useSelector(QueriesState)

    useEffect(() => {
        dispatch(getQueryDataAsync())
    }, [])

        return (
            <Fragment>
                <h1>Query Data</h1>
                <Grid container spacing={3} sx={{}}>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="Total Queries" total={queryData.fullCount} height={{min:250,max:250}} icon={<AnalyticsIcon />} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="Queries W/ Data" total={queryData.retCount} height={{min:250,max:250}} color="info" icon={< AssignmentTurnedInIcon/>} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="Return Percentage" total={queryData.retCount/queryData.fullCount*100} height={{min:250,max:250}} color="warning" icon={<PercentIcon />} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="Average Records" total={queryData.averageRecords} height={{min:250,max:250}} color="error" icon={<CalculateIcon />} />
                    </Grid>
                </Grid>

                <Separator />
                
                <QueriesDG organizations={queryData.queryData} />
                
            </Fragment>
        )

}

export default Queries