import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import { useStates } from 'react-us-states';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Tooltip,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    Select
} from "@mui/material"
// import {makeStyles} from "@mui/material/styles";
// import {green, red} from "@mui/material/colors";
import Alert from '@mui/material/Alert';
import EditSharpIcon from '@mui/icons-material/EditSharp';

// context
import { updateOrganizationAsync, clearError, OrgState } from "./orgSlice"

import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';


function PaperComponent(props) {
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  const Separator = () => {
    return <Grid item xs={12} >&nbsp;</Grid>
}


const EditOrganization = ({organization}) => {

    const { error } = useSelector(OrgState)
    const dispatch = useDispatch()

    const initialState = {
        open: false,
        name: organization.name,
        street: organization.street,
        region: organization.state,
        postalCode: organization.postalCode,
        city: organization.city,
        authId: organization.oAuthClientId, 
        apiUserId: organization.apiUserId,
        apiSub: organization.apiSub,
        isActive: organization.isActive,
    }

    const [dialog, setDialog] = useState(initialState)
    const { open, name, street, region, postalCode, city, authId, apiSub, apiUserId, isActive } = dialog

    const handleClickOpen = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = (e) => {
        dispatch(clearError())
        dispatch(updateOrganizationAsync({id:organization.id,name,street, postalCode, city, state: region, oAuthClientId: authId, apiSub, apiUserId, isActive}))
        setDialog(initialState)
    }

    const onChange = e => setDialog({...dialog, [e.target.name]: e.target.value})

    const stateOptions = useStates().map((reg, index) => {
        return {
            label: reg.name,
            value: reg.abbreviation,
            key: index
         }
    })

    return (
        <Fragment>
            <IconButton color='inherit' onClick={handleClickOpen} style={{marginLeft: "30px"}}>
                <Tooltip title='Edit Organization'>
                    <EditSharpIcon fontSize='small' style={{color: '#939393'}}/>
                </Tooltip>
            </IconButton>                   
    
            <Dialog open={open} id="editPersonModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
                <DialogTitle id='form-dialog-title'>Update Organization Info</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { error && <Alert severity={'error'}>{error}</Alert> }
                    </DialogContentText>

                    <Separator />

                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            type='text'
                            id='name'
                            name='name'
                            label='Name'
                            value={name}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                            size={'small'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size={'small'}
                            type='text'
                            id='street'
                            name='street'
                            label='Street Address'
                            value={street}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size={'small'}
                            type='text'
                            id='city'
                            name='city'
                            label='City'
                            value={city}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" >
                            <InputLabel size={'small'} htmlFor="outlined-age-native-simple">State</InputLabel>
                            <Select
                                native
                                size={'small'}
                                value={region}
                                onChange={onChange}//{(val) => handleChange(val)}
                                label="State"
                                inputProps={{
                                    name: 'region',
                                    id: 'region',
                                }}
                                >
                                <option aria-label="None" value="" />
                                {stateOptions.map(item => {
                                    return (<option key={item.key} value={item.value}>{item.label}</option>);
                                    })}
                            </Select>
                        </FormControl>
                        
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size={'small'}
                            type='text'
                            id='postalCode'
                            name='postalCode'
                            label='Postal Code'
                            value={postalCode}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size={'small'}
                            type='text'
                            id='authId'
                            name='authId'
                            label='Auth Id'
                            value={authId}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size={'small'}
                            type='text'
                            id='apiUserId'
                            name='apiUserId'
                            label='API User Id'
                            value={apiUserId}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size={'small'}
                            type='text'
                            id='apiSub'
                            name='apiSub'
                            label='API Sub'
                            value={apiSub}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl variant="outlined" sx={{minWidth: 125}} >
                            <InputLabel size={'small'} htmlFor="outlined-age-native-simple">Status</InputLabel>
                            <Select
                                native
                                size={'small'}
                                value={isActive}
                                onChange={onChange}
                                label="Status"
                                inputProps={{
                                    name: 'isActive',
                                    id: 'isActive',
                                }}
                                >
                                <option aria-label="None" value="" ></option>
                                <option aria-label='True' value='true' >Active</option>
                                <option aria-label="False" value="false" >In-Active </option>
                            </Select>
                        </FormControl>    
                    </Grid>
                </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default EditOrganization