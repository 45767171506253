import React, { useEffect } from 'react'
// import { useAuth0 } from '@auth0/auth0-react'
// import axios from "axios";
// import HomeButtons from './HomeButtons';
import HomeSearch from './HomeSearch';
import DashboardDetails from '../features/dashboard/DashboardDetails';


let isUserLoaded = false

const Home = () => {

    // const { isAuthenticated, isLoading, user, logout, getIdTokenClaims, getAccessTokenSilently, loginWithPopup } = useAuth0()

    // const authorizedUsers = []

    return (
        <div>
            <h1>Home</h1>
            <HomeSearch />
            <DashboardDetails />
            {/* <HomeButtons /> */}
            {/*<p>IsAuthenticated: {isAuthenticated ? "true" : "false"}</p>*/}
            {/*<p>IsLoading: {isLoading ? "true" : "false"}</p>*/}
            {/*<button onClick={logout}>Logout</button>*/}
            {/*<button onClick={loginWithPopup}>Login</button>*/}
        </div>
    )
}

export default Home