import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
// state
import {updateDocServerAsync, AppHealthState} from './appHealthSlice'

import Spinner from '../../layouts/Spinner'

// MUI
import { Box, Tooltip, Button,
         Card, Grid, Typography,
         Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer,
        } from "@mui/material"





const UpdateDocServerProcessing = ({data}) => { 
    const dispatch = useDispatch()

    const handleDeactivate = () => {
        // console.log('Deactivate')
        dispatch(updateDocServerAsync({id:data.id,status:false, field:'process'}))
    }
    const handleActivate = () => {
        // console.log('Activate')
        dispatch(updateDocServerAsync({id:data.id,status:true, field:'process'}))
    }

    if(!data) return <Spinner />
    return <Fragment>
        {data.process &&
        <Button size='small' color='inherit' variant="contained" sx={{paddingLeft:1.55, paddingRight:1.55, textTransform: 'none', backgroundColor: '#c6c5c7'}} onClick={handleDeactivate}>
                Do Not Process
            </Button> 
        }
       {!data.process &&
        <Button color='inherit' size='small' variant="contained" sx={{backgroundColor: '#c6c5c7', textTransform: 'none'}} onClick={handleActivate}>
                Allow Processing
            </Button> 
        }
            
        </Fragment>

}

export default UpdateDocServerProcessing