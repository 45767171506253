import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import XLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver'
import axios from 'axios'
import moment from 'moment'
import { fCurrency } from '../../utils/formatNumber'

import {Tooltip,IconButton} from "@mui/material"

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';


const DownloadAttachment = ({bill}) => {

    const exportToExcel = async (attachment) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        const fileEx = '.xlsx'

        const ws = XLSX.utils.json_to_sheet(attachment)
        const wb = {Sheets: {'Patients': ws}, SheetNames: ['Patients']}
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
        const data = new Blob([excelBuffer], {type: fileType})
        FileSaver.saveAs(data, 'PatientList' + fileEx)
    }

    const handleClick = async () => { 
        // dispatch(getBillAttachmentAsync(bill.billId))
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Billing/$attachment/${bill.billId}`).then((data) =>{
            let attachmentData = []

            data.data.forEach(d => {
                d['Query Date'] = moment(d['Query Date']).format('YYYY-MM-DD')
                d['Delivered Date'] = moment(d['Delivered Date']).format('YYYY-MM-DD')
                d['Amount'] = fCurrency(d['Amount'])
                attachmentData.push(d)
            })

            exportToExcel(attachmentData)
        })
    }


    return (
        <Fragment>
            <IconButton color='inherit' onClick={handleClick} >
                <Tooltip title='Download Attachment'>
                    <DownloadForOfflineIcon fontSize='small' style={{color: '#939393'}}/>
                </Tooltip>
            </IconButton>                   
    
            
        </Fragment>
    )
}
export default DownloadAttachment